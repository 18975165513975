exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-credits-tsx": () => import("./../../../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */),
  "component---src-pages-ekibimiz-tsx": () => import("./../../../src/pages/ekibimiz.tsx" /* webpackChunkName: "component---src-pages-ekibimiz-tsx" */),
  "component---src-pages-hakkimizda-tsx": () => import("./../../../src/pages/hakkimizda.tsx" /* webpackChunkName: "component---src-pages-hakkimizda-tsx" */),
  "component---src-pages-hizmetlerimiz-tsx": () => import("./../../../src/pages/hizmetlerimiz.tsx" /* webpackChunkName: "component---src-pages-hizmetlerimiz-tsx" */),
  "component---src-pages-iletisim-tsx": () => import("./../../../src/pages/iletisim.tsx" /* webpackChunkName: "component---src-pages-iletisim-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

